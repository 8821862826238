/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-02-23 11:05:55
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-03-25 16:24:17
 * @FilePath: \qctsw-vue\packages\app\src\logic\user\login.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import type { FormInst } from 'naive-ui'
import { useAuth } from '@/stores/auth'
import { useMessage } from '@/composables/useNotification'
import { accountLoginApi, logoutApi, phoneLoginApi } from '@/server'
import type {
  AccountLoginParams,
  PhoneLoginParams,
  UserInfoResponse,
} from '@/server/types'
import { CLIENT_TYPE_CODE_CONST } from '@/shared/constants'
import type { FetchResponseType } from '@/server/request/types'
import { validateHandle } from '@/shared/function'

export async function _logicLoginSubmit(options: {
  loading: Ref<boolean>
  fn: () => Promise<FetchResponseType<UserInfoResponse>>
}) {
  const { loading, fn } = options
  const authStore = useAuth()
  const { hideLoginPopup } = useLoginPopupEvent()

  try {
    loading.value = true
    const res = await fn()
    authStore.setUserInfo(res.data)
    loading.value = false
    useNotification.success({
      title: '提示',
      content: '登录成功，欢迎来到汽车投诉网',
      duration: 3000,
      closable: true,
    })
    hideLoginPopup()
  }
  catch (error: any) {
    const msg = getErrorMsg(error)
    recordError(error)
    useDialog.error({
      title: '登录失败',
      content: msg,
      positiveText: '确定',
      showIcon: false,
    })
  }
  finally {
    loading.value = false
  }
}

export async function logicLoginSubmit(options: {
  loading: Ref<boolean>
  formRef: Ref<FormInst | null>
  fn: () => Promise<FetchResponseType<UserInfoResponse>>
}) {
  const { loading, formRef, fn } = options
  const { isOk } = await validateHandle(formRef)
  if (!isOk)
    return

  await _logicLoginSubmit({ loading, fn })
}

export function logicPhoneLogin(params: Omit<PhoneLoginParams, 'clientTypeCode'>) {
  return phoneLoginApi({
    ...params,
    clientTypeCode: CLIENT_TYPE_CODE_CONST,
  })
}

export function logicAccountLogin(params: Omit<AccountLoginParams, 'clientTypeCode'>) {
  return accountLoginApi({
    ...params,
    clientTypeCode: CLIENT_TYPE_CODE_CONST,
  })
}

export function logicLogout() {
  logoutApi().catch(() => {})
  useAuth().clearLogin()
  useMessage.success('已退出登录')
  window.location.reload()
}
